import * as React from "react";
import Hero from "../hero/hero";
import TextSection from "../textSection/TextSection";
import Image from "../../images/for-developers-hero.jpg";
import FadeIn from "../../components/fade/fade";

const TextPage = ({ title, align, margin, children }) => {
  return (
    <>
      <Hero
        title={title}
        align={align}
        margin={margin}
        titleMaxWidth="93.6rem"
        backgroundUrl={Image}
      />

      <FadeIn>
        <TextSection>{children}</TextSection>
      </FadeIn>
    </>
  );
};

export default TextPage;
