import * as React from "react";
import * as styles from "./TextSection.module.scss";
import Container from "../../components/container/container";

const TextSection = ({ children }) => {
  console.log(children);
  return (
    <section className={`${styles.section}`}>
      <Container>
        <div className={styles.textBlock}>{children}</div>
      </Container>
    </section>
  );
};

export default TextSection;
